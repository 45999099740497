export const Action = {
  AddChat: "0",
  AddOptions: "2",
  Intent: "1"
};

function isSameSource(oldChats, newChatSource) {
  if (oldChats && oldChats.length) {
    const lastChatSource = oldChats[oldChats.length - 1].source;
    return lastChatSource == newChatSource;
  }
  return false;
}

export const contextReducer = (
  state,
  { type, items, source, options, intent, optionLayout }
) => {
  switch (type) {
    case Action.AddOptions:
      const withOptions = [
        ...state.items,
        {
          options,
          layout: optionLayout
        }
      ];
      return {
        ...state,
        items: withOptions
      };
    case Action.AddChat:
      const newChats = items.map((item, index) => ({
        ...item,
        isContinue: index > 0|| isSameSource(state.items, source),
        source
      }));
      const newItems = [...state.items, ...newChats];
      return {
        ...state,
        items: newItems,
      };
    case Action.Intent:
      return {
        ...state,
        intent
      };
  }
  return state;
};
