import axois from "axios";
const API_URL = process.env.REACT_APP_BASE_URL;

let sessionId;

export async function postReply(option = {}, context) {
  const url = `${API_URL}chat/`;
  try {
    const response = await axois.post(url, {
      ...context, // Need to fetch from localstorage
      optionId: option.id,
      session: sessionId
    });
    let { options, texts, session, ...rest } = response.data;
    sessionId = session;
    const isEmptyMessage =
      (!texts || !texts.length) && (!options || !options.length);
    if (isEmptyMessage) {
      texts = [
        "Sorry, No response from the server. Please try after sometime."
      ];
      options = [];
      rest.autoReply = false;
    }
    return {
      texts,
      options,
      ...rest,
    };
  } catch {
    return {
      texts: [
        "Oops!!! We are not able to reach server.",
        "Try after sometime."
      ],
      options: []
    };
  }
}
