import {
  faPowerOff,
  faHandPointRight,
  faBan,
  faShoppingCart,
  faGift,
  faExclamationCircle,
  faUndo,
  faSms,
  faRupeeSign,
  faSearch,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

export const SOURCE = {
  user: "user",
  server: "server"
};

export const StatusEnum = {
  init: "-",
  ready: "ready",
  progress: "progress",
  cancelled: "cancelled",
  success: "success",
  failed: "failed"
};

export const colorMap = {
  red: "#e84118"
};

export const iconMap = {
  "power-off": faPowerOff,
  ban: faBan,
  "shopping-cart": faShoppingCart,
  inr: faRupeeSign,
  "not-found": faExclamationCircle,
  gift: faGift,
  "undo": faUndo,
  sms: faSms,
  search: faSearch,
  lock: faLock,
  "hand-right": faHandPointRight
};
