import React from "react";
import ChatItem from "../ChatItem";
import Options from "../Options";

export default function Chat({ items }) {
  return (
    <div>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {!item.options && <ChatItem {...item} />}
          {item.options && (
            <Options items={item.options} layout={item.layout} disabled />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
