import React from 'react';
import logo from './logo.svg';
// import './App.css';
import ChatPage from "./pages/ChatPage";

function App() {
  return (
    <div> <ChatPage /> </div>
  );
}

export default App;
