import React, { useState, useEffect, useRef, useReducer } from "react";
import Chat from "../components/Chat";
import Options from "../components/Options";
import ProgressIndicator from "../components/ProgressIndicator";
import { postReply } from "../services/chat";
import { SOURCE } from "../config";
import { Action, contextReducer } from "./ChatPage.reducer";

export default function ChatPage() {
  let endOfChatEl = useRef(null);
  const [options, setOptions] = useState([]);
  const [optionLayout, setOptionLayout] = useState();
  const [isBusy, setIsBusy] = useState(false);
  const [context, dispatch] = useReducer(contextReducer, {
    userId: "34455", // Need to fetch from localstorage
    items: [],
    intent: null
  });

  useEffect(() => {
    sendReply();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [options]);

  function scrollToBottom(retry = true) {
    setTimeout(() => {
      if (endOfChatEl) {
        endOfChatEl.current.scrollIntoView({
          behavior: "smooth"
        });
      }
      if (retry) setTimeout(() => scrollToBottom(false), 700);
    }, 100);
  }

  function processOptions({ options, autoReply, intent }) {
    if (autoReply) {
      sendReply(options[0], intent);
      return;
    }
    setIsBusy(false);
    options && setOptions(options);
  }

  function processResponse({ texts, ...rest }) {
    function _process(items) {
      if (!items.length) return processOptions(rest);
      const firstItem = items.shift();
      addChat([firstItem], SOURCE.server);
      (chats => setTimeout(() => _process(chats), 500))([...items]);
      scrollToBottom();
    }
    const formattedItems = texts.map(text => ({ text }));
    _process(formattedItems);
  }

  async function sendReply(option, autoReplyIntent = null) {
    setIsBusy(true);
    let { intent, layout, ...restOfResponse } = await postReply(
      option,
      {
        intent: autoReplyIntent || context.intent,
        userId: context.userId
      }
    );
    dispatch({ type: Action.Intent, intent });
    processResponse({ ...restOfResponse, intent }); //formattedItems, {options, autoReply});
    setOptionLayout(layout);
  }

  const addChat = (items, source) => {
    dispatch({ type: Action.AddChat, items, source });
  };

  async function onOptionSelect(option) {
    if (options.length > 1) {
      dispatch({ type: Action.AddOptions, options, optionLayout });
    }
    setOptions([]);
    addChat([{ ...option }], SOURCE.user);
    await sendReply(option);
  }

  return (
    <React.Fragment>
      <div style={{ margin: "1rem", marginBottom: "3rem" }}>
        <Chat items={context.items} />
        {isBusy && <ProgressIndicator busy={isBusy} callout={!context.items.length} />}
        <Options
          items={options}
          onSelect={onOptionSelect}
          layout={optionLayout}
        />
      </div>
      <div ref={endOfChatEl}></div>
    </React.Fragment>
  );
}
