import React, { useState, useEffect } from "react";
import { ChatCtnr, ChatContent } from "../Styles";
import styled from "styled-components";
import { SOURCE } from "../../config";

export default function ProgressIndicator({ busy, color, callout }) {
  const [pos, setPos] = useState(0);
  const posDetails = {
    pos: "",
    timer: null
  };

  function toggleSufix() {
    if (posDetails.pos < 4) posDetails.pos += 1;
    else posDetails.pos = 0;
    setPos(posDetails.pos);
  }

  useEffect(() => {
    function resetSufix() {
      if (posDetails.timer) clearInterval(posDetails.timer);
      if (busy) {
        posDetails.timer = setInterval(toggleSufix, 150);
      } else {
        setPos(0);
      }
    }
    resetSufix();
    return () => {
      if (posDetails.timer) clearInterval(posDetails.timer);
    };
  }, [busy]);

  return (
    <ChatCtnr source={SOURCE.server} callout={callout}>
      <ChatContent source={SOURCE.server}>
        {[0, 1, 2, 3].map(index => (
          <BusyItem key={index} current={pos === index}>
            &nbsp;
          </BusyItem>
        ))}
      </ChatContent>{" "}
    </ChatCtnr>
  );
}

const BusyItem = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  background: ${props => (props.current ? "#2980b9" : "#bdc3c7")};
  margin: 0px 0.3rem 0px 0;
  display: inline-block;
  line-height: 0.6rem;
`;
