import React from "react";
import ReactMarkdown from "react-markdown";
import { ChatLeftImg, ChatCtnr, ChatContent } from "../Styles";

function LinkRenderer(props) {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
}

export default function ChatItem({ text, source, isContinue, imgtype }) {
  let cleanedText = text.split("<br/>").join("  ");
  cleanedText = cleanedText.replace(/<\/?[^>]+(>|$)/g, "");
  return (
    <ChatCtnr source={source} callout={!isContinue}>
      <ChatContent source={source} className={source} callout={!isContinue}>
        <ReactMarkdown
          source={cleanedText}
          escapeHtml={false}
          renderers={{ link: LinkRenderer }}
        />
      </ChatContent>
    </ChatCtnr>
  );
}
