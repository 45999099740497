import React from "react";
import styled, { css } from "styled-components";
import ReactMarkdown from "react-markdown";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChatLeftImg, borderRadius, appearAnimation } from "../Styles";
import { colorMap, iconMap } from "../../config";

function trim(text, length) {
  if (text.length > length) return text.slice(0, length) + "...";
  return text;
}

function trimItemText(item) {
  item.text = trim(item.text, 20);
  return item;
}

function formatItems(items, layout) {
  if (layout == "grid") {
    items = items.map(item => {
      if (item.special) return item;
      return trimItemText(item);
    });
  }
  return items;
}

function PragraphRender(props, layout) {
  if (layout === "grid") return <GridPara>{props.children}</GridPara>;
  return <p>{props.children}</p>;
}

export default function Options({ items = [], onSelect, layout, disabled }) {
  const specialOption = items.find(i => i.special);
  const normalOptions = items.filter(i => !i.special);
  items = formatItems(items, layout);
  const { OptionsCtnr, OptionItem } = componentMap[layout] || defaultComponets;
  return (
    <React.Fragment>
      <OptionsCtnr layout={layout}>
        {normalOptions.map(o => (
          <OptionItem
            layout={layout}
            key={o.id}
            disabled={disabled}
            onClick={() => onSelect && onSelect(o)}
            layout={layout}
          >
            <Icon icon={o.icon} color={o.iColor} />
            {o.img && (
              <ChatLeftImg src={o.img} type={o.imgtype} layout={layout} />
            )}
            <ReactMarkdown
              source={o.text}
              escapeHtml={false}
              renderers={{ paragraph: props => PragraphRender(props, layout) }}
            />
          </OptionItem>
        ))}
      </OptionsCtnr>
      {specialOption && !disabled && (
        <OptionsCtnrBase special={true}>
          <OptionItemBase
            option={specialOption}
            onClick={() => onSelect && onSelect(specialOption)}
          >
            <Icon icon={specialOption.icon} color={specialOption.iColor} />
            <ReactMarkdown source={specialOption.text} escapeHtml={false} />
          </OptionItemBase>
        </OptionsCtnrBase>
      )}
    </React.Fragment>
  );
}

function Icon({ icon, color }) {
  if (!icon) return null;
  return (
    <FontAwesomeIcon icon={iconMap[icon]} style={{ color: colorMap[color] }} />
  );
}

const GridPara = styled.div`
  margin: 4px 2px -4px 7px;
  text-align: left;
  font-size: 0.9rem;
`;

const OptionsCtnrBase = styled.div`
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin-top: ${props => (props.special ? "0" : "-0rem")};
`;

const OptionItemBase = styled.button`
  z-index: 100;
  display: flex;
  border: none;
  padding: 9px 12px 9px 12px;
  animation: ${props => (props.disabled ? '' : css `${appearAnimation} 700ms ease-in`)};
  cursor: pointer;
  margin: 0 0px 0.2rem 0.2rem;
  border-radius: 30px;
  /* border-radius: ${borderRadius}; */
  font-size: ${props => (props.layout === "grid" ? "13px" : "inherit")};
  color: ${props => (props.disabled ? "#666" : "#34495e")};
  font-weight: 500;
  background: #fff;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  border: 1px dashed #dcdde1;
  /* border: ${props => (props.disabled ? "1px  #dcdde1" : null)}; */
  /* box-shadow: ${props =>
    props.disabled ? "none" : "1px 1px 2px 0px rgba(0,0,0,0.25)"}; */
  &:hover {
    background: #ecf0f1;
  }
`;

const BrickInner = styled(OptionItemBase)`
  border: 1px dashed #dcdde1;
  box-shadow: none;
`;
const BrickOuter = styled(OptionsCtnrBase)``;

const BlockInner = styled(OptionItemBase)`
  border-radius: ${borderRadius};
  width: 90%;
`;
const BlockOuter = styled(OptionsCtnrBase)``;

const GridInner = styled(OptionItemBase)`
  flex-direction: column;
  border-radius: ${borderRadius};
  padding: 0px 0px 9px 0px;
  margin: 8px 8px 8px 0;
  border: none;
  box-shadow: ${props =>
    props.disabled ? null : "1px 1px 2px 0px rgba(0,0,0,0.25)"};
`;

const GridOuter = styled(OptionsCtnrBase)`
  display: grid;
  overflow-y: ${props => (isMobile ? "scroll" : "inherit")};
  grid-template-columns: ${props =>
    isMobile ? "repeat(10, 32%)" : "repeat(auto-fill, 118px)"};
`;

const componentMap = {
  block: {
    OptionsCtnr: BlockOuter,
    OptionItem: BlockInner
  },
  brick: {
    OptionsCtnr: BrickOuter,
    OptionItem: BrickInner
  },
  grid: {
    OptionsCtnr: GridOuter,
    OptionItem: GridInner
  }
};
const defaultComponets = {
  OptionsCtnr: OptionsCtnrBase,
  OptionItem: OptionItemBase
};
