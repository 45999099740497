import styled, { keyframes, css } from "styled-components";
import { SOURCE } from "../config";

export const borderRadius = "6px";

const user = {
  bg: "#2f3640",
  fg: "#dcdde1"
};

const server = {
  bg: "#dcdde1",
  fg: "#2f3640"
};

export const ChatLeftImg = styled.img`
  float: left;
  min-height: ${props => (props.layout === "grid" ? "120px" : null)};
  width: ${props => (props.layout === "grid" ? "100%" : null)};
  height: ${props =>
    props.layout === "grid"
      ? "inherit"
      : props.type === "icon"
      ? "2.5rem"
      : "5rem"};
  margin: 0 0.5rem 0 0;
  /* margin-left: ${props =>
    props.layout === "grid" || props.type === "icon" ? "0" : "-0.5rem"}; */
  border-radius: ${props =>
    props.layout == "grid" ? `${borderRadius} ${borderRadius} 0px 0px` : "6px"};
`;

const serverAfter = `
&:after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: -10px;
    top: 0px;
    bottom: auto;
    border: 10px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-color: ${server.bg} transparent transparent transparent;
}
`;

const userAfter = `
&:after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    right: -10px;
    top: 0px;
    bottom: auto;
    border: 9px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-color: ${user.bg} transparent transparent ${user.bg};
}
`;

export const ChatCtnr = styled.div`
  animation: ${props => (props.disabled ? '' : css `${appearAnimation} 200ms ease-in`)};
  text-align: ${props => (props.source === SOURCE.user ? "right" : "left")};
  clear: both;
  position: relative;
  margin-top: ${props => (!props.callout ? "-1rem" : null)};
  ${props =>
    props.callout &&
    (props.source === SOURCE.server ? serverAfter : userAfter)};
`;

export const ChatContent = styled.div`
  animation: ${props => (props.disabled ? '' : css `${appearAnimation} 200ms ease-in`)};
  border-radius: ${props =>
    props.source === SOURCE.server
      ? `0 ${borderRadius} ${borderRadius} ${borderRadius}`
      : `${borderRadius} 0 ${borderRadius} ${borderRadius}`};
  text-align: left;
  display: inline-block;
  margin: 0 0 1.1rem 0;
  font-weight: 600;
  padding: 7px 11px 7px 11px;
  max-width: 85%;
  background: ${props =>
    props.source === SOURCE.server ? server.bg : user.bg};
  color: ${props => (props.source === SOURCE.server ? server.fg : user.fg)};
`;

export const appearAnimation = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`;
